import React, { useState } from "react"
import { TextMedium } from "../text/Text.js"
import {
  CtaRow,
  CtaField,
  StyledCta,
  StyledCtaButton,
  CtaButtonContainer,
} from "./style.js"
import { Column100 } from "../column/Column"
import { Heading } from "../title/Title.js"

const CtaButton = ({ buttonText, buttonLink }) => (
  <StyledCtaButton to={buttonLink}>{buttonText}</StyledCtaButton>
)

const Cta = (...props) => {
  const [firstName, setFirstname] = useState("")
  const [email, setEmail] = useState("")

  const handleSubmit = event => {
    event.preventDefault()

    var xhr = new XMLHttpRequest()
    var url =
      "https://api.hsforms.com/submissions/v3/integration/submit/25880547/c21d121c-66c0-466b-a953-ef21367ed6ca"

    var data = {
      submittedAt: Date.now(),
      fields: [
        {
          name: "email",
          value: email,
        },
        {
          name: "firstname",
          value: firstName,
        },
      ],
      context: {
        // "hutk": ':hutk', // include this parameter and set it to the hubspotutk cookie value to enable cookie tracking on your submission
        pageUri: window.location.pathname,
        pageName: document.title,
      },
      legalConsentOptions: {
        consent: {
          consentToProcess: true,
          text:
            "I agree to allow Example Company to store and process my personal data.",
          communications: [
            {
              value: true,
              subscriptionTypeId: 999,
              text:
                "I agree to receive marketing communications from Example Company.",
            },
          ],
        },
      },
    }

    var final_data = JSON.stringify(data)

    xhr.open("POST", url)
    xhr.setRequestHeader("Content-Type", "application/json")

    xhr.onreadystatechange = function () {
      if (xhr.readyState === 4 && xhr.status === 200) {
        window.location = "https://www.schoolofcontent.net/training-subscribe-success" 
      } else if (xhr.readyState === 4 && xhr.status === 404) {
        window.location = "https://www.schoolofcontent.net/404/"
      }
    }

    xhr.send(final_data)
  }

  return (
    <StyledCta
      name="training"
      data-netlify="true"
      data-netlify-honeypot="bot-field"
      method="POST"
      redirect="https://www.schoolofcontent.net/training-subscribe-success/"
      onSubmit={handleSubmit}
      isLight
    >
      <CtaRow isCentered>
        <Column100 isFloating>
          <Heading heading="Get notified when we add new courses" />
          <TextMedium
            textMedium="Add your e-mail address below if you can't find what you're looking for yet, but you'd like 
            us to keep you informed."
          />
          <CtaButtonContainer>
            <CtaField
              type="email"
              id="email"
              name="email"
              placeholder="Your email address*"
              value={email}
              onChange={e => setEmail(e.target.value)}
              required="required"
              isWide
            />
            <CtaField
              type="text"
              id="firstName"
              name="firstName"
              placeholder="Your name"
              value={firstName}
              onChange={e => setFirstname(e.target.value)}
            />
            <CtaButton
              buttonText="Get notified"
              buttonLink="/training-subscribe-success/"
            />
          </CtaButtonContainer>
        </Column100>
      </CtaRow>
    </StyledCta>
  )
}

export default Cta
