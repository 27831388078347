import React from "react"
import {
  AuthorWrapper,
  AuthorName,
  SnippetAuthor,
  AuthorAvatar,
  AuthorTitle,
  StyledDetailsWrapper,
} from "./style.js"

export const Author = ({ ...props }) => (
  <AuthorWrapper>
    <AuthorAvatar src={props.avatarImg} alt="" />
    <SnippetAuthor>
      <AuthorName>{props.authorName}</AuthorName>
      <AuthorTitle>{props.authorTitle}</AuthorTitle>
    </SnippetAuthor>
  </AuthorWrapper>
)

export const DetailsWrapper = ({ children, ...props }) => (
  <StyledDetailsWrapper isCentered={props.isCentered}>
    {children}
  </StyledDetailsWrapper>
)
