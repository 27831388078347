import styled from "styled-components"
import { colors, backgrounds } from "../../styles/colors.js"
import { sizes } from "../../styles/sizes"
import { fontSizes, fonts } from "../../styles/typography"
import { ButtonContainer } from "../button/Button.js"

export const StyledCta = styled.form`
  display: flex;
  flex-flow: column wrap;
  justify-content: center;
  align-items: center;
  align-content: center;
  border-radius: 8px;
  background-color: ${props =>
    props.isLight
      ? `${backgrounds.bgPinkLightest}`
      : `${colors.colorTextMedium}`};
  color: ${colors.colorTextDark};
  padding: ${sizes.sizeL};
  margin: ${props => (props.isCentered ? "2vw auto" : "2vw 0")};
`

export const CtaRow = styled.div`
  margin-bottom: ${sizes.sizeXL};
  width: 80%;
  display: flex;
  flex-flow: row wrap;
  justify-content: ${props => (props.isCentered ? "center" : "flex-start")};

  &:last-of-type {
    margin-bottom: ${sizes.sizeXXL};
  }
`

export const CtaField = styled.input`
  border: 2px solid transparent;
  border-radius: 8px;
  flex-grow: 1;
  background: ${colors.colorWhite};
  color: ${colors.colorTextMedium};
  padding: ${sizes.sizeM} ${sizes.sizeL};
  font-size: ${fontSizes.fontS};
  font-family: ${fonts.fontPrimary};
  margin-right: ${sizes.sizeS};
  margin-top: ${sizes.sizeS};

  &::placeholder {
    font-family: ${fonts.fontPrimary};
  }

  &:hover {
    border: 2px solid ${colors.colorContentDark};
  }

  &:focus {
    color: ${colors.colorContentDark};
    border: 2px solid ${colors.colorContentDark};
    outline: none;
  }
`

export const CtaLabel = styled.label`
  color: ${colors.colorTextMedium};
  font-size: ${fontSizes.fontS};
`

export const CtaButton = styled.button`
  text-decoration: none;
  flex-grow: 1;
  font: inherit;
  font-weight: 600;
  border-radius: 8px;
  padding: 1rem 2rem;
  margin-top: ${sizes.sizeS};
  margin-right: ${sizes.sizeS};
  transition: all 0.3s cubic-bezier(0, 0.89, 0.44, 1);
`

export const StyledCtaButton = styled(CtaButton)`
  border: none;
  background: linear-gradient(
    to right,
    ${colors.colorContentDark},
    ${colors.colorContentDarker}
  );
  color: ${colors.colorWhite};

  &:hover {
    background: ${colors.colorWhite};
    box-shadow: 0 9px 21px 0 ${colors.colorContentLight};
    -moz-box-shadow: 0 9px 21px 0 ${colors.colorContentLight};
    -webkit-box-shadow: 0 9px 21px 0 ${colors.colorContentLight};
    -webkit-text-fill-color: ${colors.colorContentDark};
    cursor: pointer;
  }
`

export const CtaButtonContainer = styled(ButtonContainer)`
  justify-content: ${props => (props.isCentered ? "center" : "flex-start")};
  width: 100%;
`
