import styled from "styled-components"
import { colors } from "../../styles/colors"
import { sizes } from "../../styles/sizes"

export const StyledDetailsWrapper = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: ${props => (props.isCentered ? "center" : "flex-start")};
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  border-radius: 2rem;
  background-color: ${props =>
    props.isLight ? `${colors.colorTextLightest}` : `${colors.colorWhite}`};
  color: ${colors.colorTextDark};
  width: auto;

  &:last-of-type {
    margin-bottom: ${sizes.sizeXXL};
  }
`

export const DetailsWrapperCourses = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: ${props => (props.isCentered ? "center" : "flex-start")};
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  border-radius: 2rem;
  background-color: ${props =>
    props.isLight ? `${colors.colorTextLightest}` : `${colors.colorWhite}`};
  color: ${colors.colorTextDark};
  width: auto;

  &:last-of-type {
    margin-bottom: ${sizes.sizeXXL};
  }
`

export const StyledCourseDetails = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: ${props => (props.isCentered ? "center" : "flex-start")};
  align-items: center;
  margin-bottom: ${sizes.sizeS};
  margin-left: auto;
  margin-right: auto;
  color: ${colors.colorTextDark};

  ol,
  ul,
  p {
    display: block;
    width: 100%;
    line-height: ${sizes.sizeXL};
  }
`