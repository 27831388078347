import styled from "styled-components"
import { sizes } from "../../styles/sizes"
import { colors } from "../../styles/colors.js"
import { fontSizes } from "../../styles/typography.js"

export const AuthorWrapper = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  align-items: center;
  margin-left: 0;
  width: 100%;
`

export const SnippetAuthor = styled.div`
  display: flex;
  flex-flow: column wrap;
`

export const AuthorName = styled.p`
  color: ${colors.colorTextPurple};
  font-size: ${fontSizes.fontM};
  margin-bottom: 0;
  font-weight: 700;
  width: 200px;
`

export const AuthorTitle = styled.p`
  font-size: ${fontSizes.fontS};
  color: ${colors.colorTextPurple};
  font-weight: 500;
`

export const AuthorAvatar = styled.img`
  height: 60px;
  width: 60px;
  border-radius: 100%;
  display: inline-block;
  overflow: hidden;
  object-fit: cover;
  margin-right: ${sizes.sizeM};
  margin-left: 0;
`

export const StyledDetailsWrapper = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: ${props => (props.isCentered ? "flex-start" : "flex-end")};
  width: 100%;
`
