import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout/Layout.js"
import { Row, BlogRow, NewRow} from "../components/row/Row"
import {
  BlogTitle,
  SubHeading,
  Subtitle,
} from "../components/title/Title.js"
import { TextMedium, TextSmall } from "../components/text/Text"
import { Section, AboveFold } from "../components/section/Section"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faCalendar,
} from "@fortawesome/free-solid-svg-icons"
import Seo from "../components/seo"
import { Column50, Column33, Column100, Column66 } from "../components/column/Column"
import Cta from "../components/ctablock/Cta"
import { colors } from "../styles/colors"
import { StyledDetailsWrapper, StyledCourseDetails, DetailsWrapperCourses } from "../components/detailsWrapper/style"
import { Breadcrumb } from "../components/breadcrumb/Breadcrumb.js"
import { Author } from "../components/author/Author.js"
import authorImg from '../static/authors/andreeamacoveiciuc.jpeg'
import {ImageHome} from "../components/image/Image.js"
import { ButtonContainer, PrimaryButton, Contact } from "../components/button/Button.js"


export const pageQuery = graphql`
  query ContentfulCourse($slug: String!) {
    site {
      siteMetadata {
        title
        siteUrl
      }
    }
    contentfulTraining(slug: { eq: $slug }) {
      courseBanner {
        file {
          url
        }
        gatsbyImageData(width: 1200, quality: 90) 
        resize(height: 630, width: 1200) {
          src
        }
      }
      courseContent {
        childMarkdownRemark {
          html
          internal {
            content
          }
        }
      }
      date(formatString: "Do MMMM, YYYY")
      duration
      id
      level
      price
      subtitle
      title
      slug
      courseLink
    }
  }
`


class TrainingTemplate extends React.Component {
  render() {
    const post = this.props.data.contentfulTraining
    const image = post.courseBanner.resize.src

    return (
      <Layout location={this.props.location}>
        <Seo
          title={post.title}
          description={post.subtitle}
          pathname={this.props.location.pathname}
          image={image}
          featuredImage={image}
        />

        <AboveFold isLight>
          <Row>
            <Breadcrumb>
              <li>
                <a href="/content-strategy-training/">All courses</a>
              </li>
              <li>
                <a href={`${post.slug}`}>{post.title}</a>
              </li>
            </Breadcrumb>
          </Row>
            <Row isCentered>
            <Column50>
              <TextMedium textMedium="Level: From Beginner to Advanced"/>
              <BlogTitle isPrimary title={post.title}/>
              <SubHeading subheading={post.subtitle} />
              <ButtonContainer>
                <Contact buttonText="Coming soon" buttonLink={post.courseLink} />
              </ButtonContainer>
            </Column50>
            <Column50>
              <ImageHome src={image} alt="homepage" />
            </Column50>
          </Row>
        </AboveFold>

        <Section>
          <Row isCentered>
            <Column50>
              <Author
                    authorName="Andreea Macoveiciuc"
                    avatarImg={authorImg}
                    authorTitle="Strategist @School of Content"
                  />
            </Column50>
            <Column50>
              <StyledDetailsWrapper>
                <FontAwesomeIcon
                  icon={faCalendar}
                  size="1x"
                  style={{
                    padding: "0 0.5rem",
                    verticalAlign: "middle",
                    margin: "14px 0 16px",
                  }}
                  color={colors.colorTextMedium}
                />
                <TextMedium textMedium={`Duration: ${post.duration}` } />
              </StyledDetailsWrapper>
            </Column50>
          </Row>
          <BlogRow isCentered>
              <Subtitle isCentered isPrimary subtitle="Course content" />
              <StyledCourseDetails
                  dangerouslySetInnerHTML={{
                    __html: post.courseContent.childMarkdownRemark.html,
                  }}
                />
          </BlogRow>
            
            <Row isCentered>
              <Column100 isCentered>
                <PrimaryButton
                    buttonText="Book your spot"
                    buttonLink={post.courseLink}
                  />
                  <TextSmall textSmall="*Tickets for this course are sold via Eventbrite." />
              </Column100>
            </Row>

        </Section>
        <Cta/>
      </Layout>
    )
  }
}

export default TrainingTemplate
